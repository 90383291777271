import React from 'react';
import Loadable from 'react-loadable';

import Loader from '../components/stateless/Loader/Loader';
import { ingressPath } from '../const/global';

const LoadableMainTemplate = Loadable({
    loader: () => import('../components/stateless/MainTemplate/MainTemplate'),
    loading: () => <Loader />
});

let indexRoutes = [
    {
        path: "/",
        name: "Home",
        component: LoadableMainTemplate,
        exact: true
    },
    {
        path: "/" + ingressPath,
        name: "Home",
        component: LoadableMainTemplate, // will be changed
        exact: false
    }
];


export default indexRoutes;