import { clearSearchFilter } from './customerDB';

const CHANGE_ROUTE = 'reducer/CHANGE_ROUTE';
const CHANGE_INNER_ROUTE = 'reducer/CHANGE_INNER_ROUTE';

export default (state = {
    selected_route: "offerCategory",
}, action) => {
    switch (action.type) {
        case CHANGE_ROUTE:
            return {
                selected_route: action.selected_route,
            };
        case CHANGE_INNER_ROUTE:
            return {
                ...state,
                inner_route: action.inner_route,
            }
        default:
            return state;
    }
}

export function changeSelectedRoute(selected_route) {
    return function (dispatch) {
        dispatch(clearSearchFilter())
        return dispatch({
            type: CHANGE_ROUTE,
            selected_route: selected_route,
        })
    }
}

export function changeInnerRoute(inner_route) {
    return function (dispatch) {
        return dispatch({
            type: CHANGE_INNER_ROUTE,
            inner_route: inner_route
        })
    }
}