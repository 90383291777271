const regexMailControl = /^(([^<>()[\]\\.,;:\s@(")]+(\.[^<>()[\]\\.,;:\s@(")]+)*)|((").+(")))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regexOnlyAcceptNumber = /^[+]?\d*[.]?\d*$/;
const regexMaxThreeDecimalPoint = /^\d+(\.\d{1,3})?$/;
const regexMaxEightDecimalPoint = /^\d+(\.\d{1,8})?$/;
const regexTelephoneNumber = /^$|^\+?[1-9]\d{1,14}$/; // ^$ accepts empty string. It controls when entering an input.
const regexTelephoneNumberNotAcceptZero = /^\+?[1-9]\d{1,14}$/; // It is the Regex of BE Phone number. It is used before the data is sended to BE on FE.
const regexWebsiteControl = /^(https):\/\//;
const regexWebsiteControlWithW = /^(www\.)/;
const regexHexColor = /^#([A-Fa-f0-9]{6})$/;
const regexIntegerControl = /^\d\d*$/;
const regexEmptyHTMLTagControl = /^((<(?!\/)[^>]+>)+(&nbsp;)*(<\/[^>]+>)+)+$/;
const regexLineBreakTagControl = /^((<br>)*)$/;
const regexCarriageReturn = /(\r\n|\n|\r)/gm;
const regexVersion = /^(\d+\.)?(\d+\.)?(\*|\d+)$/;
const regexLink = /^(^(http(s)?):\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

module.exports = {
  regexMailControl,
  regexOnlyAcceptNumber,
  regexMaxThreeDecimalPoint,
  regexMaxEightDecimalPoint,
  regexTelephoneNumber,
  regexWebsiteControl,
  regexHexColor,
  regexIntegerControl,
  regexTelephoneNumberNotAcceptZero,
  regexEmptyHTMLTagControl,
  regexLineBreakTagControl,
  regexCarriageReturn,
  regexLink,
  regexVersion,
  regexWebsiteControlWithW
};
