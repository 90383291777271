import * as Endpoints from "../const/endpoints";
import * as NetworkServices from "../services/networkServices";
import { WhatsNewprocessTypes } from "../const/global";
import { responseCode } from "../const/responseCode";

// Actions

const CHANGE_SELECTED_DEVICE_TYPE = "reducer/CHANGE_SELECTED_DEVICE_TYPE";
const GET_WHATISNEW_BY_TYPEID = "reducer/GET_WHATISNEW_BY_TYPEID";
const ADD_WHATISNEW = "reducer/ADD_WHATISNEW";
const ACTIVE_DEACTIVE = "reducer/ACTIVE_DEACTIVE";
const UPDATE_ISACTIVE = "reducer/UPDATE_ISACTIVE";

// Reducers
export default (
  state = {
    selectedDeviceType: {
      id: 1,
      name: "iOS"
    },
    loadingWhatIsNewsByTypeId: false,
    allWhatIsNewsByTypeId: [],
    activeLoader: false,
    showWhatIsNewPublishError:false
  },
  action
) => {
  switch (action.type) {
    case CHANGE_SELECTED_DEVICE_TYPE:
      return {
        ...state,
        selectedDeviceType: action.selectedDeviceType
      };
    case GET_WHATISNEW_BY_TYPEID:
      return {
        ...state,
        selectedDeviceType: action.selectedDeviceType,
        loadingWhatIsNewsByTypeId: action.loadingWhatIsNewsByTypeId,
        allWhatIsNewsByTypeId: action.allWhatIsNewsByTypeId
      };
    case ADD_WHATISNEW:
      return {
        ...state,
        loadingWhatIsNewsByTypeId: action.loadingWhatIsNewsByTypeId,
        showWhatIsNewPublishError: action.showWhatIsNewPublishError
      };
    case ACTIVE_DEACTIVE:
      return {
        ...state,
        activeLoader: action.activeLoader
      };
    case UPDATE_ISACTIVE:
      return {
        ...state,
        allWhatIsNewsByTypeId: action.allWhatIsNewsByTypeId
      };
    default:
      return { ...state };
  }
};

// Action Creators

export function getWhatIsNewByDeviceTypeId() {
  return function(dispatch, getState) {
    const selectedDeviceType = getState().whatisnew.selectedDeviceType;
    dispatch({
      type: GET_WHATISNEW_BY_TYPEID,
      loadingWhatIsNewsByTypeId: true,
      allWhatIsNewsByTypeId: [],
      selectedDeviceType: selectedDeviceType
    });

    // dispatch(handleValidationError(false));

    NetworkServices.requestData(
      "GET",
      Endpoints.getWhatIsNewByDeviceTypeId +
        "?deviceType=" +
        selectedDeviceType.id,
      null,
      false,
      false
    )
      .then(response => {
        if (response.data.code === 1 && response.data.data !== undefined) {
          var whatIsNewList = response.data.data;
          return dispatch({
            type: GET_WHATISNEW_BY_TYPEID,
            loadingWhatIsNewsByTypeId: false,
            allWhatIsNewsByTypeId: whatIsNewList,
            selectedDeviceType: selectedDeviceType
          });
        } else {
          return dispatch({
            type: GET_WHATISNEW_BY_TYPEID,
            loadingWhatIsNewsByTypeId: false,
            allWhatIsNewsByTypeId: [],
            selectedDeviceType: selectedDeviceType,
            error: {
              status: true,
              message: "Error: " + response.data.message
            }
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: GET_WHATISNEW_BY_TYPEID,
          loadingWhatIsNewsByTypeId: false,
          allWhatIsNewsByTypeId: [],
          selectedDeviceType: selectedDeviceType,
          error: {
            status: true,
            message: "Error: " + error
          }
        });
      });
  };
}

export function changeSelectedDeviceType(id, name) {
  let selectedDeviceType = {
    id: id,
    name: name
  };
  return function(dispatch) {
    return dispatch({
      type: CHANGE_SELECTED_DEVICE_TYPE,
      selectedDeviceType: selectedDeviceType
    });
  };
}

export function addWhatIsNew(whatIsNew) {
  return function(dispatch, getState) {
    let whatIsNewtoAdd = {
      Id: whatIsNew.id,
      DeviceType: getState().whatisnew.selectedDeviceType.id,
      ApplicationVersion: whatIsNew.version,
      IsActive: true,
      IsLatestVersion: true,
      Translations: [
        {
          LanguageCode: "en",
          Headline: whatIsNew.headlineen,
          InformationText: whatIsNew.InformationTexten
        },
        {
          LanguageCode: "ko",
          Headline: whatIsNew.headlineko,
          InformationText: whatIsNew.InformationTextko
        }
      ],
      UpdateHistory: [
        {
          ProcessType:
            whatIsNew.id === 0 // means add
              ? WhatsNewprocessTypes.Add
              : WhatsNewprocessTypes.Edit
        }
      ]
    };

    dispatch({
      type: ADD_WHATISNEW,
      loadingWhatIsNewsByTypeId: true,
      showWhatIsNewPublishError: false
    });
    return NetworkServices.requestData(
      "POST",
      Endpoints.upsertVersion,
      whatIsNewtoAdd,
      false,
      false
    )
      .then(response => {
        if (response.data.code === responseCode.success) {
          dispatch(getWhatIsNewByDeviceTypeId());
          return dispatch({
            type: ADD_WHATISNEW,
            loadingWhatIsNewsByTypeId: false,
            showWhatIsNewPublishError: false
          });
        } else {
          return dispatch({
            type: ADD_WHATISNEW,
            loadingWhatIsNewsByTypeId: false,
            showWhatIsNewPublishError: true
          });

          
        }
      })
      .catch(error => {
        return dispatch({
          type: ADD_WHATISNEW,
          loadingWhatIsNewsByTypeId: false,
          showWhatIsNewPublishError: true
        });
      });
  };
}

export function activeLoader(id, value) {
  return function(dispatch, getState) {
    dispatch({
      type: ACTIVE_DEACTIVE,
      activeLoader: true
    });
    return NetworkServices.requestData(
      "POST",
      Endpoints.updateStatus,
      {
        Id: id,
        IsActive: value
      },
      false,
      false
    )
      .then(response => {
        if (response.data.code === responseCode.success) {
          getState().whatisnew.allWhatIsNewsByTypeId[0] = response.data.data;
          dispatch({
            type: ACTIVE_DEACTIVE,
            activeLoader: false
          });

          return dispatch({
            type: UPDATE_ISACTIVE,
            allWhatIsNewsByTypeId: getState().whatisnew.allWhatIsNewsByTypeId
          });
        } else {
          return dispatch({
            type: ACTIVE_DEACTIVE,
            activeLoader: false
          }); // think active error
        }
      })
      .catch(error => {
        return dispatch({
          type: ACTIVE_DEACTIVE,
          activeLoader: false
        }); // think active error
      });
  };
}

export function closePublishVersionError(status) {
  return function(dispatch, getState) {
    dispatch({
      type: ADD_WHATISNEW,
      showWhatIsNewPublishError: status,
      loadingWhatIsNewsByTypeId:getState().whatisnew.loadingWhatIsNewsByTypeId
    });
  };
}