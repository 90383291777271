export const availableTimes =[
    {
        dayOfWeek: 1,
        openingTime: "09:00",
        closingTime: "17:00",
        isOpen: true
    },
    {
        dayOfWeek: 2,
        openingTime: "09:00",
        closingTime: "17:00",
        isOpen: true
    },
    {
        dayOfWeek: 3,
        openingTime: "09:00",
        closingTime: "17:00",
        isOpen: true
    },
    {
        dayOfWeek: 4,
        openingTime: "09:00",
        closingTime: "17:00",
        isOpen: true
    },
    {
        dayOfWeek: 5,
        openingTime: "09:00",
        closingTime: "17:00",
        isOpen: true
    },
    {
        dayOfWeek: 6,
        openingTime: "09:00",
        closingTime: "17:00",
        isOpen: true
    },
    {
        dayOfWeek: 7,
        openingTime: "09:00",
        closingTime: "17:00",
        isOpen: true
    }
]

export const branch = {
    name: null,
    description: null,
    email: null,
    phone: null,
    address: null,
    addressLatitude: null,
    addressLongitude: null,
    addressRadius: null,
    hasFlexibleBusinessHour:false,
    availableTimes: availableTimes
}

export const branchMandatoryFields = [
    'name',
    'addressLatitude',
    'addressLongitude',
    'address',
]

