import  React, { useEffect } from  "react";
import { connect } from 'react-redux';
import { Route } from  "react-router-dom";

import * as Endpoints from '../const/endpoints';

const  PrivateRoute = ({ userLoggedIn, path, exact, component }) => {
    useEffect(() => {
        if(!userLoggedIn)
        {
            window.location.href = `${Endpoints.baseUrl}/${Endpoints.login}`;
        }
    }, [userLoggedIn]);

    return userLoggedIn ?
        (<Route  path={path}  exact={exact} component={component} />) : 
        (<div/>);
};

const mapStateToProps = (state) => ({
    userLoggedIn: state.auth.userLoggedIn
});

export default connect(mapStateToProps, null)(PrivateRoute);