import * as Endpoints from '../const/endpoints';
import * as NetworkServices from '../services/networkServices';
import _ from 'lodash';
import queryString from "query-string";

import { offerCategoryTypes } from '../const/global';
import { uploadFile } from './fileUpload';
import { removeMultipleSpaces } from '../helper/stringHelper';
import { handleValidationError } from './modal';
import { responseCode } from '../const/responseCode';

// Actions

const GET_ALL_OFFERS_BY_TYPE_ID = "reducer/GET_ALL_OFFERS_BY_TYPE_ID";
const CHANGE_SELECTED_OFFER_CATEGORY = "reducer/CHANGE_SELECTED_OFFER_CATEGORY";
const DELETE_OFFER = "reducer/DELETE_OFFER";
const EDIT_OFFER = "reducer/EDIT_OFFER";
const GET_OFFER = "reducer/GET_OFFER";
const UPDATE_OFFER = "reducer/UPDATE_OFFER";
const CREATE_OFFER = "reducer/CREATE_OFFER";
const CLEAR_STORED_OFFERS = "reducer/CLEAR_STORED_OFFERS";
const GET_FILTER_TAGS = "reducer/GET_FILTER_TAGS";
const UPDATE_STICK_TOP = "reducer/UPDATE_STICK_TOP";
const UPDATE_OFFER_ORDER = "reducer/UPDATE_OFFER_ORDER";
const CLEAR_STORED_OFFER_ORDER = "reducer/CLEAR_STORED_OFFER_ORDER";


// Reducers

export default (
  state = {
    selectedOfferCategory: {
      id: 6,
      name: "Benefit"
    },
    allOfferByTypeId: [],
    error: {
      status: "",
      message: ""
    },
    navigateOfferTable: false,
    visibleSuccess: false,
    stickLoader: false
  },
  action
) => {
  switch (action.type) {
    case CHANGE_SELECTED_OFFER_CATEGORY:
      return {
        ...state,
        selectedOfferCategory: action.selectedOfferCategory
      };
    case GET_ALL_OFFERS_BY_TYPE_ID:
      return {
        ...state,
        allOfferByTypeId: action.allOfferByTypeId,
        loadingAllOfferByTypeId: action.loadingAllOfferByTypeId,
        selectedOfferCategory: action.selectedOfferCategory,
        navigateOfferTable: action.navigateOfferTable,
        error: action.error,
        visibleSuccess: action.visibleSuccess,
        initialOfferList: action.initialOfferList,
        editedOfferOrder: action.initialOfferList
      };
    case DELETE_OFFER:
      return {
        ...state,
        loadingDeleteOffer: action.loadingDeleteOffer,
        error: action.error
      };
    case EDIT_OFFER:
      return {
        ...state,
        editedOfferDetail: _.cloneDeep(action.editedOfferDetail)
      };
    case GET_OFFER:
      return {
        ...state,
        selectedOfferDetail: action.selectedOfferDetail,
        editedOfferDetail: _.cloneDeep(action.selectedOfferDetail),
        loadingGetOfferDetail: action.loadingGetOfferDetail,
        error: action.error
      };
    case CREATE_OFFER:
      return {
        ...state,
        loadingCreateOffer: action.loadingCreateOffer,
        editedOfferDetail: _.cloneDeep(action.editedOfferDetail),
        selectedOfferDetail: _.cloneDeep(action.selectedOfferDetail),
        navigateOfferTable: action.navigateOfferTable,
        error: action.error
      };
    case UPDATE_OFFER:
      return {
        ...state,
        loadingUpdateOffer: action.loadingUpdateOffer,
        editedOfferDetail: _.cloneDeep(action.editedOfferDetail),
        selectedOfferDetail: _.cloneDeep(action.selectedOfferDetail),
        visibleSuccess: action.visibleSuccess,
        error: action.error
      };
    case CLEAR_STORED_OFFERS:
      return {
        ...state,
        editedOfferDetail: action.editedOfferDetail,
        selectedOfferDetail: action.selectedOfferDetail,
        selectedOfferCategory: action.selectedOfferCategory,
        allOfferByTypeId: action.allOfferByTypeId,
        initialOfferList: action.allOfferByTypeId,
        editedOfferOrder: action.allOfferByTypeId

      };
    case CLEAR_STORED_OFFER_ORDER:
      return {
        ...state,
        initialOfferList: action.initialOfferList,
        editedOfferOrder: action.editedOfferOrder
      };
    case GET_FILTER_TAGS:
      return {
        ...state,
        filterTags: action.filterTags,
        loadingGetFilterTags: action.loadingGetFilterTags
      };
    case UPDATE_STICK_TOP:
      return {
        ...state,
        stickLoader: action.stickLoader
      };
    case UPDATE_OFFER_ORDER:
      return {
        ...state,
        editedOfferOrder: action.editedOfferOrder
      };
    default:
      return {
        ...state
      };
  }
};

// Action Creators
export function changeSelectedOfferCategory(id, name) {
  let selectedOfferCategory = {
    id: id,
    name: name
  };
  return function (dispatch) {
    return dispatch({
      type: CHANGE_SELECTED_OFFER_CATEGORY,
      selectedOfferCategory: selectedOfferCategory
    });
  };
}

export function getAllOfferByTypeId() {
  return function (dispatch, getState) {
    const selectedCategory = getState().offer.selectedOfferCategory;
    dispatch({
      type: GET_ALL_OFFERS_BY_TYPE_ID,
      allOfferByTypeId: [],
      selectedOfferCategory: selectedCategory,
      loadingAllOfferByTypeId: true,
      navigateOfferTable: false
    });
    dispatch(handleValidationError(false));
    let offerList = [];
    NetworkServices.requestData(
      "GET",
      Endpoints.getAllOffersByTypeId + "?typeId=" + selectedCategory.id,
      null,
      false,
      false
    )
      .then(response => {
        if (response.data.code === 1 && response.data.data !== undefined) {
          offerList = response.data.data;
          return dispatch({
            type: GET_ALL_OFFERS_BY_TYPE_ID,
            allOfferByTypeId: offerList,
            selectedOfferCategory: selectedCategory,
            loadingAllOfferByTypeId: false,
            navigateOfferTable: false,
            visibleSuccess: false,
            initialOfferList: offerList
          });
        } else {
          return dispatch({
            type: GET_ALL_OFFERS_BY_TYPE_ID,
            allOfferByTypeId: [],
            selectedOfferCategory: selectedCategory,
            loadingAllOfferByTypeId: false,
            navigateOfferTable: false,
            initialOfferList: [],
            error: {
              status: true,
              message: "Error: " + response.data.message
            }
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: GET_ALL_OFFERS_BY_TYPE_ID,
          allOfferByTypeId: [],
          selectedOfferCategory: selectedCategory,
          loadingAllOfferByTypeId: false,
          navigateOfferTable: false,
          error: {
            status: true,
            message: "Error" + error
          }
        });
      });
  };
}

// DELETE

export function deleteOffer(category_id, offer_id) {
  let deleteEndpoint = "";
  return function (dispatch) {
    dispatch({
      type: DELETE_OFFER,
      loadingDeleteOffer: true
    });
    switch (category_id) {
      case offerCategoryTypes.benefit.id:
        deleteEndpoint = Endpoints.deleteBenefitOfferById;
        break;
      case offerCategoryTypes.afterSalesVoucher.id:
        deleteEndpoint = Endpoints.deleteAfterSalesOfferById;
        break;
      case offerCategoryTypes.event.id:
        deleteEndpoint = Endpoints.deleteEventOfferById;
        break;
      default:
        break;
    }
    return NetworkServices.requestData(
      "GET",
      deleteEndpoint + "?offerId=" + offer_id,
      null,
      false,
      false
    )
      .then(response => {
        dispatch(getAllOfferByTypeId());
        return dispatch({
          type: DELETE_OFFER,
          loadingDeleteOffer: false
        });
      })
      .catch(error => {
        return dispatch({
          type: DELETE_OFFER,
          loadingDeleteOffer: false,
          error: {
            status: true,
            message: "Error: " + error
          }
        });
      });
  };
}

// CREATE

export function createOffer(category_id, new_offer_information, formData) {
  let createEndpoint = "";
  return function (dispatch, getState) {
    dispatch({
      type: CREATE_OFFER,
      loadingCreateOffer: true,
      editedOfferDetail: getState().offer.editedOfferDetail,
      error: {
        status: false,
        message: ""
      }
    });
    switch (category_id) {
      case offerCategoryTypes.benefit.id:
        createEndpoint = Endpoints.createBenefitOffer;
        break;
      case offerCategoryTypes.afterSalesVoucher.id:
        createEndpoint = Endpoints.createAfterSalesOffer;
        break;
      case offerCategoryTypes.event.id:
        createEndpoint = Endpoints.createEventOffer;
        break;
      default:
        break;
    }

    if (!formData.entries().next().done) {
      let offerWithImages;
      let uploadFileAction = dispatch(uploadFile(formData));
      return uploadFileAction.then(response => {
        if (!response.error.status) {
          switch (category_id) {
            case offerCategoryTypes.benefit.id:
              offerWithImages = getState().offer.editedOfferDetail;
              if (
                offerWithImages.headerPicture instanceof File ||
                offerWithImages.headerPicture instanceof Object
              ) {
                offerWithImages.headerPicture = response.hashOfImages[0];
              }
              dispatch(requestCreateOffer(createEndpoint, offerWithImages));
              break;
            case offerCategoryTypes.afterSalesVoucher.id:
              offerWithImages = getState().offer.editedOfferDetail;
              if (
                offerWithImages.headerPicture instanceof File ||
                offerWithImages.headerPicture instanceof Object
              ) {
                offerWithImages.headerPicture = response.hashOfImages[0];
              }
              dispatch(requestCreateOffer(createEndpoint, offerWithImages));
              break;
            case offerCategoryTypes.event.id:
              let count = 0;
              offerWithImages = getState().offer.editedOfferDetail;
              if (
                offerWithImages.headerPicture instanceof File ||
                offerWithImages.headerPicture instanceof Object
              ) {
                offerWithImages.headerPicture = response.hashOfImages[0];
                count++;
              }
              offerWithImages.eventImagesList.forEach(item => {
                if (item.hash instanceof File || item.hash instanceof Object) {
                  item.hash = response.hashOfImages[count];
                  count++;
                  return item;
                }
              });
              dispatch(requestCreateOffer(createEndpoint, offerWithImages));
              break;
            default:
              break;
          }
        } else {
          return dispatch({
            type: CREATE_OFFER,
            loadingCreateOffer: false,
            editedOfferDetail: getState().offer.editedOfferDetail,
            error: {
              status: true,
              message: "Error: " + response.error.message
            }
          });
        }
      });
    } else {
      dispatch(requestCreateOffer(createEndpoint, new_offer_information));
    }
  };
}

// EDIT

export function editOfferDetail(edited_offer_detail) {
  return function (dispatch) {
    return dispatch({
      type: EDIT_OFFER,
      editedOfferDetail: _.cloneDeep(edited_offer_detail)
    });
  };
}

// GET

export function getOfferDetail(category_id, selected_offer_id) {
  let getEndpoint;
  let qs = {};
  qs.offerId = selected_offer_id;
  return function (dispatch) {
    dispatch({
      type: GET_OFFER,
      loadingGetOfferDetail: true,
      error: {
        status: false,
        message: ""
      }
    });
    switch (category_id) {
      case offerCategoryTypes.benefit.id:
        getEndpoint = Endpoints.getBenefitOfferDetail;
        break;
      case offerCategoryTypes.event.id:
        getEndpoint = Endpoints.getEventOfferDetail;
        break;
      case offerCategoryTypes.testDrive.id:
        getEndpoint = Endpoints.getTestDriveOfferDetail;
        break;
      case offerCategoryTypes.afterSalesVoucher.id:
        getEndpoint = Endpoints.getAfterSalesOfferDetail;
        break;
      default:
        break;
    }
    return NetworkServices.requestData(
      "GET",
      `${getEndpoint}?${queryString.stringify(qs)}`,
      null,
      false,
      false
    )
      .then(response => {
        if (response.data.code === 1) {
          let selectedOfferDetail = response.data.data;
          selectedOfferDetail.translations = _.orderBy(
            selectedOfferDetail.translations,
            "languageCode",
            "asc"
          );
          if (category_id === offerCategoryTypes.event.id) {
            selectedOfferDetail.eventDateTimeProgramList = _.orderBy(
              selectedOfferDetail.eventDateTimeProgramList,
              "order",
              "asc"
            );
          }
          return dispatch({
            type: GET_OFFER,
            selectedOfferDetail: selectedOfferDetail,
            loadingGetOfferDetail: false
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: GET_OFFER,
          loadingGetOfferDetail: false,
          error: {
            status: true,
            message: "Error: " + error
          }
        });
      });
  };
}

// UPDATE

export function updateOffer(category_id, edited_offer, formData) {
  let updateEndpoint;
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_OFFER,
      loadingUpdateOffer: true,
      error: {
        status: false,
        message: ""
      },
      editedOfferDetail: edited_offer,
      selectedOfferDetail: getState().offer.selectedOfferDetail
    });
    switch (category_id) {
      case offerCategoryTypes.benefit.id:
        updateEndpoint = Endpoints.updateBenefitOffer;
        break;
      case offerCategoryTypes.afterSalesVoucher.id:
        updateEndpoint = Endpoints.updateAfterSalesOffer;
        break;
      case offerCategoryTypes.event.id:
        updateEndpoint = Endpoints.updateEventOffer;
        break;
      case offerCategoryTypes.testDrive.id:
        updateEndpoint = Endpoints.updateTestDriveOffer;
        break;
      default:
        break;
    }
    if (!formData.entries().next().done) {
      let uploadFileAction = dispatch(uploadFile(formData));
      let offerWithImages;
      return uploadFileAction.then(response => {
        if (!response.error.status) {
          switch (category_id) {
            case offerCategoryTypes.testDrive.id:
              offerWithImages = getState().offer.editedOfferDetail;
              if (
                offerWithImages.offerImage instanceof File ||
                offerWithImages.offerImage instanceof Object
              ) {
                offerWithImages.offerImage = response.hashOfImages[0];
              }
              dispatch(requestUpdateOffer(updateEndpoint, offerWithImages));
              break;
            case offerCategoryTypes.benefit.id:
              offerWithImages = getState().offer.editedOfferDetail;
              if (
                offerWithImages.headerPicture instanceof File ||
                offerWithImages.headerPicture instanceof Object
              ) {
                offerWithImages.headerPicture = response.hashOfImages[0];
              }
              dispatch(requestUpdateOffer(updateEndpoint, offerWithImages));
              break;
            case offerCategoryTypes.afterSalesVoucher.id:
              offerWithImages = getState().offer.editedOfferDetail;
              if (
                offerWithImages.headerPicture instanceof File ||
                offerWithImages.headerPicture instanceof Object
              ) {
                offerWithImages.headerPicture = response.hashOfImages[0];
              }
              dispatch(requestUpdateOffer(updateEndpoint, offerWithImages));
              break;
            case offerCategoryTypes.event.id:
              let count = 0;
              offerWithImages = getState().offer.editedOfferDetail;
              if (offerWithImages.headerPicture instanceof File || offerWithImages.headerPicture instanceof Object) {
                offerWithImages.headerPicture = response.hashOfImages[0];
                count++;
              }
              offerWithImages.eventImagesList.forEach((item) => {
                if (item.hash instanceof File || item.hash instanceof Object) {
                  item.hash = response.hashOfImages[count];
                  count++;
                  return item;
                }
              })
              dispatch(requestUpdateOffer(updateEndpoint, offerWithImages));
              break;
            default:
              break;
          }
        } else {
          return dispatch({
            type: UPDATE_OFFER,
            loadingUpdateOffer: false,
            editedOfferDetail: getState().offer.editedOfferDetail,
            error: {
              status: true,
              message: "Error: " + response.error.message,
            }
          });
        }
      });
    } else {
      dispatch(requestUpdateOffer(updateEndpoint, edited_offer));
    }
  };
}

export function getFilterTags() {
  return function (dispatch, getState) {
    const storeOffer = getState().offer;
    const selectedLanguage = getState().language.selectedLanguage;
    dispatch({
      type: GET_FILTER_TAGS,
      loadingGetFilterTags: true,
      filterTags: []
    });
    return NetworkServices.requestData(
      "GET",
      `${Endpoints.getFilterTags}?lang=${selectedLanguage}`,
      null,
      false,
      false
    )
      .then(response => {
        if (response.data.code === responseCode.success) {
          return dispatch({
            type: GET_FILTER_TAGS,
            loadingGetFilterTags: false,
            filterTags: response.data.data
          });
        } else {
          return dispatch({
            type: GET_FILTER_TAGS,
            loadingGetFilterTags: false,
            filterTags: storeOffer.filterTags
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: GET_FILTER_TAGS,
          loadingGetFilterTags: false,
          filterTags: storeOffer.filterTags
        });
      });
  };
}

function requestCreateOffer(createEndpoint, offer) {
  return function (dispatch, getState) {
    return NetworkServices.requestData(
      "POST",
      createEndpoint,
      offer,
      false,
      false
    )
      .then(response => {
        if (response.data.code === 1) {
          dispatch(handleValidationError(false));
          return dispatch({
            type: CREATE_OFFER,
            loadingCreateOffer: false,
            editedOfferDetail: response.data.data,
            selectedOfferDetail: response.data.data,
            navigateOfferTable: true,
            error: {
              status: false,
              message: ""
            }
          });
        } else {
          return dispatch({
            type: CREATE_OFFER,
            loadingCreateOffer: false,
            editedOfferDetail: getState().offer.editedOfferDetail,
            error: {
              status: true,
              message: response.data.message
            }
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: CREATE_OFFER,
          loadingCreateOffer: false,
          editedOfferDetail: getState().offer.editedOfferDetail,
          error: {
            status: true,
            message: "Error: " + error
          }
        });
      });
  };
}

function requestUpdateOffer(updateEndpoint, edited_offer) {
  return function (dispatch, getState) {
    let trimmedOffer = removeMultipleSpaces(edited_offer);
    return NetworkServices.requestData(
      "POST",
      updateEndpoint,
      trimmedOffer,
      false,
      false
    )
      .then(response => {
        if (response.data.code === 1) {
          dispatch(handleValidationError(false));
          return dispatch({
            type: UPDATE_OFFER,
            editedOfferDetail: response.data.data,
            selectedOfferDetail: _.cloneDeep(response.data.data),
            loadingUpdateOffer: false,
            visibleSuccess: true
          });
        } else {
          return dispatch({
            type: UPDATE_OFFER,
            loadingUpdateOffer: false,
            editedOfferDetail: edited_offer,
            selectedOfferDetail: getState().offer.selectedOfferDetail,
            visibleSuccess: false
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: UPDATE_OFFER,
          loadingUpdateOffer: false,
          editedOfferDetail: edited_offer,
          selectedOfferDetail: getState().offer.selectedOfferDetail,
          error: {
            status: true,
            message: "Error: " + error
          }
        });
      });
  };
}

export function clearStoredOffers() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_STORED_OFFERS,
      allOfferByTypeId: [],
      editedOfferDetail: null,
      selectedOfferDetail: null,
      initialOfferList: [],
      editedOfferOrder: [],
      selectedOfferCategory: {
        id: 15,
        name: "Aftersales Voucher"
      }
    })
  }
}

export function clearStoredOfferOrder() {
  return function (dispatch) {
    return dispatch({
      type: CLEAR_STORED_OFFER_ORDER,
      initialOfferList: [],
      editedOfferOrder: [],
    })
  }
}

export function updateBenefitMostUsedStatus(benefitId, mostUsedStatus) {
  return function (dispatch, getState) {
    let benefitPatchBody = [{
      "op": "add",
      "path": "/mostUsed",
      "value": mostUsedStatus
    }]

    return NetworkServices.requestData("PATCH", `${Endpoints.patchBenefitOffer}/${benefitId}`, benefitPatchBody, false, false).then((response) => {
      if (response.data.code === 1) {
        return dispatch({
          type: UPDATE_OFFER,
          loadingUpdateBenefitMostUsed: false,
        })
      } else {
        return dispatch({
          type: UPDATE_OFFER,
          loadingUpdateBenefitMostUsed: false,
        })
      }
    }).catch((error) => {
      return dispatch({
        type: UPDATE_OFFER,
        loadingUpdateBenefitMostUsed: false,
        error: {
          status: true,
          message: "Error: " + error,
        }
      })
    })
  }
}

//HTTP REQ
export function updateOffersOrder(offers, offerCategoryId) {
  return function (dispatch, getState) {
    let updateOfferOrderBody = offers;
    let selectedEndpoint;

    if(offerCategoryId == 15) {
      selectedEndpoint = `${Endpoints.updateAfterSalesOrder}`;
    } else {
      selectedEndpoint = `${Endpoints.updateOffersOrder}`;
    }

    return NetworkServices.requestData("POST", selectedEndpoint, updateOfferOrderBody, false, false).then((response) => {
      if (response.data.code === 1) {
        return dispatch({
          type: UPDATE_OFFER,
          loadingupdateOffersOrder: false,
        })
      } else {
        return dispatch({
          type: UPDATE_OFFER,
          loadingupdateOffersOrder: false,
        })
      }
    }).catch((error) => {
      return dispatch({
        type: UPDATE_OFFER,
        loadingupdateOffersOrder: false,
        error: {
          status: true,
          message: "Error: " + error,
        }
      })
    })
  }
}

//FOR NOTIFYING OTHER COMPONENTS
export function editOfferOrder(editedOfferOrder) {
  return function (dispatch) {
    return dispatch({
      type: UPDATE_OFFER_ORDER,
      editedOfferOrder: _.cloneDeep(editedOfferOrder)
    });
  };
}

