export function setBodyOverflow(type) {
    // types : hidden : auto;
    document.body.style.overflow = type;
}

export function addClassToElement(elementId, className) {
    document.getElementById(elementId).classList.add(className);
}

export function removeClassFromElement(elementId, className) {
    document.getElementById(elementId).classList.remove(className);
}