import { setBodyOverflow } from '../helper/pageHelper.jsx';

// Actions

const OPEN_MODAL = 'reducer/OPEN_MODAL';
const CLOSE_MODAL = 'reducer/CLOSE_MODAL';
const OPEN_OFFER_TYPE_SELECTION_MODAL = 'reducer/OPEN_OFFER_TYPE_SELECTION_MODAL';
const OPEN_INVOICE_UPLOAD_MODAL = 'reducer/OPEN_INVOICE_UPLOAD_MODAL';
const OPEN_SEARCHBOX_MODAL = 'reducer/OPEN_SEARCHBOX_MODAL';
const HANDLE_GENERIC_ERROR = 'reducer/HANDLE_GENERIC_ERROR';
const SHOW_VALIDATION_ERROR = 'reducer/SHOW_VALIDATION_ERROR';
const SHOW_MAX_FILE_UPLOAD_ERROR = 'SHOW_MAX_FILE_UPLOAD_ERROR';
const OPEN_READ_MORE_MODAL = 'reducer/OPEN_READ_MORE_MODAL';
const SHOW_WRONG_FILE_TYPE_ERROR = 'reducer/SHOW_WRONG_FILE_TYPE_ERROR';

// Reducers

export default (state = {
    modalOpenStatus: false,
    statusOfferTypeSelectionModal: false,
    showGenericError: false,
    showMaxFileUploadError: false
}, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                modalOpenStatus: action.modalOpenStatus,
                content: action.content,
                closeOnClickOutside: action.closeOnClickOutside
            }
        case CLOSE_MODAL:
            return {
                modalOpenStatus: action.modalOpenStatus,
            }
        case OPEN_OFFER_TYPE_SELECTION_MODAL:
            return {
                ...state,
                statusOfferTypeSelectionModal: action.statusOfferTypeSelectionModal,
                error: action.error
            }
        case OPEN_INVOICE_UPLOAD_MODAL:
            return {
                ...state,
                statusInvoiceUploadModal: action.statusInvoiceUploadModal,
                selectedCustomerInvoiceOrder: action.selectedCustomerInvoiceOrder
            }
        case OPEN_SEARCHBOX_MODAL:
            return {
                ...state,
                statusSearchBoxModal: action.statusSearchBoxModal,
                searchBoxType:action.searchBoxType
            }
        case OPEN_READ_MORE_MODAL:
            return{
                ...state,
                statusOpenReadMoreModal: action.statusOpenReadMoreModal,
                selectedUserId: action.selectedUserId,
                selectedMessage: action.selectedMessage
            }
        case HANDLE_GENERIC_ERROR:
            return {
                ...state,
                showGenericError: action.showGenericError,
                genericErrorCode: action.genericErrorCode,
                genericErrorTitle: action.genericErrorTitle,
                genericErrorSubtitle: action.genericErrorSubtitle,
                genericErrorContent: action.genericErrorContent,
                genericErrorButtonLabel: action.genericErrorButtonLabel,
                genericErrorLogo: action.genericErrorLogo
            }
        case SHOW_VALIDATION_ERROR:
            return {
                ...state,
                showValidationError: action.showValidationError,
            }
        case SHOW_MAX_FILE_UPLOAD_ERROR:
            return {
                ...state,
                showMaxFileUploadError: action.showMaxFileUploadError,
            }
        case SHOW_WRONG_FILE_TYPE_ERROR:
            return {
                ...state,
                showWrongFileTypeError:action.showWrongFileTypeError
            }
        default:
            return state;
    }
}

// Action Creators

// MODALS
export function openModal(content, closeOnClickOutside) {
    setBodyOverflow("hidden");
    return function (dispatch) {
        dispatch({
            type: OPEN_MODAL,
            modalOpenStatus: true,
            content: content,
            closeOnClickOutside: closeOnClickOutside
        })
    }
}

export function closeModal() {
    setBodyOverflow("auto");
    return function (dispatch) {
        dispatch({
            type: CLOSE_MODAL,
            modalOpenStatus: false,
        })
    }
}

export function openOfferTypeSelectionModal(status) {
    setBodyOverflow(status ? "hidden" : "auto");
    return function (dispatch) {
        dispatch({
            type: OPEN_OFFER_TYPE_SELECTION_MODAL,
            statusOfferTypeSelectionModal: status,
            error: {
                status: false,
                message: ""
            }
        })
    }
}

export function openInvoiceUploadModal(status, order) {
    return function (dispatch) {
        dispatch({
            type: OPEN_INVOICE_UPLOAD_MODAL,
            statusInvoiceUploadModal: status,
            selectedCustomerInvoiceOrder: order
        })
    }
}

export function openReadMoreModal(status, userId, message) {
    return function (dispatch) {
        dispatch({
            type: OPEN_READ_MORE_MODAL,
            statusOpenReadMoreModal: status,
            selectedUserId: userId,
            selectedMessage: message
        })
    }
}

export function openSearchBoxModal(status, searchBoxType) {
    return function (dispatch) {
        dispatch({
            type: OPEN_SEARCHBOX_MODAL,
            statusSearchBoxModal: status,
            searchBoxType: searchBoxType
        })
    }
}

// ERROR CASES
export function handleGenericError(status, errorInformation) {
    if (errorInformation !== undefined) {
        return function (dispatch) {
            dispatch({
                type: HANDLE_GENERIC_ERROR,
                showGenericError: status,
                genericErrorCode: errorInformation.genericErrorCode,
                genericErrorTitle: errorInformation.genericErrorTitle,
                genericErrorSubtitle: errorInformation.genericErrorSubtitle,
                genericErrorContent: errorInformation.genericErrorContent,
                genericErrorButtonLabel: errorInformation.genericErrorButtonLabel,
                genericErrorLogo: errorInformation.genericErrorLogo
            })
        }
    } else {
        return function (dispatch) {
            dispatch({
                type: HANDLE_GENERIC_ERROR,
                showGenericError: status,
            })
        }
    }
}

export function handleValidationError(status) {
    return function (dispatch) {
        dispatch({
            type: SHOW_VALIDATION_ERROR,
            showValidationError: status,
        })
    }
}

export function handleMaxFileUploadRateLimitError(status) {
    return function (dispatch) {
        dispatch({
            type: SHOW_MAX_FILE_UPLOAD_ERROR,
            showMaxFileUploadError: status,
        })
    }
}

export function showWrongFileTypeError(status){
    return function (dispatch){
        dispatch({
            type: SHOW_WRONG_FILE_TYPE_ERROR,
            showWrongFileTypeError:status
        })
    }
}
