import * as Endpoints from '../const/endpoints';
import * as NetworkServices from '../services/networkServices';
import { timezones } from '../const/global';

// Actions

const CHANGE_LNG = 'CHANGE_LNG';
const GET_SUPPORTED_LANGUAGES = 'GET_SUPPORTED_LANGUAGES';

// Reducer

export default (state = {
	selectedTimezone: timezones.kor.region,
	selectedLanguage: 'en',
	loadingChangeLang: false,
	supportedLanguages: [
		{
			"languageId": 1,
			"languageCode": "en"
		}
	],
	error: {
		status: false,
		message: ""
	}
}, action) => {
	switch (action.type) {
		case CHANGE_LNG:
			import('moment/locale/' + action.selectedLanguage + '.js')
			return {
				...state,
				selectedLanguage: action.selectedLanguage,
			};
		case GET_SUPPORTED_LANGUAGES:
			return {
				...state,
				numberOfSupportedLanguages: action.numberOfSupportedLanguages,
				supportedLanguages: action.supportedLanguages,
				loadingSupportedLanguages: action.loadingSupportedLanguages,
				error: action.error
			}

		default:
			return state;
	}
}

// Action Creators

export function changeLanguage(selectedLanguage) {
	return function (dispatch) {
		return dispatch({
			type: CHANGE_LNG,
			selectedLanguage: selectedLanguage
		});
	};
}

export function getSupportedLanguages() {
	return function (dispatch) {
		let supportedLanguages = [];
		NetworkServices.requestData("GET", Endpoints.getSupportedLanguages, null, false, false).then((response) => {
			if (response.data.data.length > 0) {
				supportedLanguages = response.data.data;
				return dispatch({
					type: GET_SUPPORTED_LANGUAGES,
					supportedLanguages: supportedLanguages,
					numberOfSupportedLanguages: supportedLanguages.length,
					loadingSupportedLanguages: false
				})
			} else {
				return dispatch({
					type: GET_SUPPORTED_LANGUAGES,
					supportedLanguages: [
						{
							"languageId": 1,
							"languageCode": "en"
						}
					],
					loadingSupportedLanguages: false
				})
			}
		}).catch((error) => {
			return dispatch({
				type: GET_SUPPORTED_LANGUAGES,
				supportedLanguages: [
					{
						"languageId": 1,
						"languageCode": "en"
					}
				],
				loadingSupportedLanguages: false,
				error: {
					status: true,
					message: "Error: " + error
				}
			})
		});
		return dispatch({
			type: GET_SUPPORTED_LANGUAGES,
			supportedLanguages: [
				{
					"languageId": 1,
					"languageCode": "en"
				}
			],
			loadingSupportedLanguages: true,
			numberOfSupportedLanguages: 2
		})
	}
}

