export const responseCode = {
    error: 0,
    success: 1,
    validation: 7,
    fileUploadRateError: 8,
    accessTokenValidationError: 42,
    unauthorizedAdminError: 47,
    errorAddPoints: 51,
    errorDeleteActiveUsers: 53,
    errorAddPointsToInactiveUser: 60,
    wrongFileType:62,
    mapping: 98,
    database: 99,
}