import React from "react";
import store from './store/index';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import 'date-input-polyfill';
import 'promise-polyfill/src/polyfill';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/overridden.css';
import './index.css';

export default (props) => (
    <Provider store={store}>
        <BrowserRouter>
            <I18nextProvider i18n={i18n}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </Provider>
);
