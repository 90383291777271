
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../redux/index';
import thunk from 'redux-thunk';

// STORE 

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__||compose;
// const composeEnhancers = compose;

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

export default store;
