import store from '../store/index';
import { handleGenericError, handleValidationError, handleMaxFileUploadRateLimitError, showWrongFileTypeError } from '../redux/modal';
import { showPointsNotAddedError, showPointsNotAddedToInactiveUsersError } from '../redux/loyaltyProgram';
import { showCannotDeleteActiveUsersError } from '../redux/customerDB';
import { responseCode } from '../const/responseCode';

export const handleResponseDataCode = (data) => {
    let errorInfo = {
        genericErrorCode: data.code,
        genericErrorTitle: 'genericErrorTitleText',
        genericErrorSubtitle: 'genericErrorSubtitleText',
        genericErrorContent: '',
        genericErrorButtonLabel: 'goHomepageButtonText',
        genericErrorLogo: 'warning_2'
    };
    switch (data.code) {
        case responseCode.success:
            store.dispatch(handleGenericError(false));
            break;
        case responseCode.validation:
            store.dispatch(handleValidationError(true));
            break;
        case responseCode.fileUploadRateError:
            store.dispatch(handleMaxFileUploadRateLimitError(true));
            break;
        case responseCode.mapping:
            errorInfo.genericErrorContent = `Mapping Error: ${data.code}, ${data.message}. Error ID:  ${data.errorId}`;
            store.dispatch(handleGenericError(true, errorInfo)); //mapping code
            break;
        case responseCode.database:
            errorInfo.genericErrorContent = `Database Error: ${data.code}, ${data.message}. Error ID:  ${data.errorId}`;
            store.dispatch(handleGenericError(true, errorInfo)); // database
            break;
        case responseCode.errorAddPoints:
            store.dispatch(showPointsNotAddedError(true));
            break;
        case responseCode.error:
            errorInfo.genericErrorContent = `Error ID:  ${data.errorId}`;
            store.dispatch(handleGenericError(true, errorInfo));
            break;
        case responseCode.errorDeleteActiveUsers:
            store.dispatch(showCannotDeleteActiveUsersError(true));
            break;
        case responseCode.errorAddPointsToInactiveUser:
            store.dispatch(showPointsNotAddedToInactiveUsersError(true, data.data));
            break;
        case responseCode.wrongFileType:
            store.dispatch(showWrongFileTypeError(true));
            break;
        case responseCode.unauthorizedAdminError:
            errorInfo.genericErrorCode = 401;
            errorInfo.genericErrorTitle = 'notAuthorizedTitleText';
            errorInfo.genericErrorSubtitle = 'notAuthorizedSubtitleText';
            errorInfo.genericErrorButtonLabel = 'logoutText';
            errorInfo.genericErrorLogo = 'lock';
            store.dispatch(handleGenericError(true, errorInfo));
            break;
        default:
            store.dispatch(handleGenericError(false));
            break;
    }
}

export const handleResponseError = (error) => {
    let errorInfo = {
        genericErrorCode: null,
        genericErrorTitle: 'genericErrorTitleText',
        genericErrorSubtitle: 'genericErrorSubtitleText',
        genericErrorContent: '',
        genericErrorButtonLabel: 'tryAgainButtonText',
        genericErrorLogo: 'warning_2'
    };
    if (!error.response) {
        store.dispatch(handleGenericError(true, errorInfo));
    } else if (error.response.status === 401) {
        errorInfo.genericErrorCode = error.response.status;
        errorInfo.genericErrorTitle = 'notAuthorizedTitleText';
        errorInfo.genericErrorSubtitle = 'notAuthorizedSubtitleText';
        errorInfo.genericErrorButtonLabel = 'loginAgainButtonText';
        errorInfo.genericErrorLogo = 'lock';
        store.dispatch(handleGenericError(true, errorInfo));
    } else {
        errorInfo.genericErrorCode = error.response.status;
        store.dispatch(handleGenericError(true, errorInfo));
    }
}