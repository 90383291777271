// ADMIN PANEL INGRESS PATH
const ingressPath = "adminpanel";

// COMMON
const daysOfWeek = [
  {
    day: 1,
    name: "Monday",
  },
  {
    day: 2,
    name: "Tuesday",
  },
  {
    day: 3,
    name: "Wednesday",
  },
  {
    day: 4,
    name: "Thursday",
  },
  {
    day: 5,
    name: "Friday",
  },
  {
    day: 6,
    name: "Saturday",
  },
  {
    day: 7,
    name: "Sunday",
  },
];
const timezones = {
  kor: { region: "Asia/Seoul", offset: "+09:00" },
};

const dateTimeFormats = {
  kor: {
    dateFormat: "YYYY-MM-DD",
    dateTimeObjectFormat: "YYYY-MM-DD HH:mm a",
    loyaltyFormat: "DD.MM.YYYY",
    loyaltyReportFormat: "DD.MM.YYYY HH:mm:ss",
  },
};

const currencies = [{ unit: "KRW", symbol: "₩" }];

const maxFileSizes = {
  // in kilobytes
  categoryPicture: { size: 3000 },
  offerPicture: { size: 3000 },
  loyaltyInvoice: { size: 5000 },
};

// Offer Category

const offerCategoryStatus = {
  0: { status: "activeText", value: 0, color: "rgb(126, 211, 33)" },
  1: { status: "inactiveText", value: 1, color: "rgb(255, 103, 96)" },
};

const offerCategoryTypes = {
  event: { id: 4, name: "Event" },
  benefit: { id: 6, name: "Benefit" },
  rental: { id: 9, name: "MBRent" },
  testDrive: { id: 10, name: "MBTest" },
  magazine: { id: 13, name: "Mercedes me Magazine" },
  longTermRental: { id: 14, name: "Long Term Rental" },
  afterSalesVoucher: { id: 15, name: "Aftersales Voucher" },
};

// OFFER
const contentType = {
  png: "data:image/png;base64, ",
  jpeg: "data:image/jpeg;base64, ",
};

const progressBarLevel = {
  0: { value: "0" },
  20: { value: "20" },
  50: { value: "50" },
  80: { value: "80" },
  100: { value: "100" },
};

const stringLengths = {
  XXXSmall: 5,
  XXSmall: 10,
  XXSmallPlus: 15,
  XSmall: 20,
  Small: 25,
  SmallPlus: 35,
  Medium: 45,
  XMedium: 50,
  Large: 80,
  XLarge: 120,
  XLargeMedium: 150,
  XLargePlus: 500,
  XXLarge: 800,
  XXXLarge: 2000,
  hexColorCode: 7,
};

const offerDetailsNextStep = [
  { text: "pleaseChooseTextOfferNextStep", value: null },
  { text: "reserveTextOfferNextStep", value: 0 },
  { text: "callAndSaveOfferNextStep", value: 1 },
  { text: "reservePlusCallAndSaveOfferNextStep", value: 2 },
];

const benefitOfferNextStep = {
  active: { value: 5 },
  nonActive: { value: 6 },
};

const eventDetailsNextStep = [
  { text: "noApplicationNextStep", value: 6 },
  { text: "applyViaEmailEventNextStep", value: 3 },
  { text: "applyViaLinkEventNextStep", value: 4 },
];

// LOYALTY PROGRAM

const loyaltyProgramCards = [
  { id: 1, title: "AddPoints", icon: "plus" },
  { id: 2, title: "DeductPoints", icon: "minus" },
  { id: 3, title: "TransactionHistory", icon: "transaction" },
  { id: 4, title: "afterSalesVouchers", icon:"aftersalesvoucher"}
];

const loyaltyProgramId = {
  AddPoints: 1,
  DeductPoints: 2,
  TransactionHistory: 3,
  afterSalesVouchers:4
};
const loyaltyTransactionTypes = [
  { value: "", text: "transactionTypePleaseChooseText" },
  // { value: 0, text: "transactionTypeCarPurchaseText" },
  { value: 1, text: "transactionTypeServiceText" },
];

const loyaltyDeductTransactionTypes = [
  { value: "", text: "transactionTypePleaseChooseText" },
  { value: 1, text: "transactionTypeServiceText" },
  { value: 2, text: "transactionTypeAccessoryPurchaseText" },
];

const customerRecordByType = [
  {
    order: 0,
    type: "choose",
    text: "customerRecordTypePleaseChooseText",
  },
  {
    order: 1,
    type: "phoneNumber",
    text: "customerRecordTypePhoneNumberText",
  },
  {
    order: 2,
    type: "emailAddress",
    text: "customerRecordTypeEmailAddressText",
  },
  {
    order: 3,
    type: "loyaltyCardNumber",
    text: "customerRecordTypeLoyaltyCardNumberText",
  },
];

// CUSTOMER DB

const membershipStatus = [
  { value: true, text: "activeText" },
  { value: false, text: "nonActiveText" },
];

const membershipStatusForSearchBar = [
  { value: null, text: "searchText" },
  { value: true, text: "activeText" },
  { value: false, text: "nonActiveText" },
];

const customerDatabasePageSize = 15;

const customerDBSearch = {
  userId: { searchType: 1, searchTerm: null },
  customer: { searchType: 2, searchTerm: null },
  loyaltyCardNumber: { searchType: 3, searchTerm: null },
  membershipStatus: { searchType: 4, searchTerm: null },
  memberSince: { searchType: 5, searchTerm: null },
  membershipExpireDate: { searchType: 6, searchTerm: null },
};

// What's New

const WhatsNewprocessTypes = {
  Add: 1,
  Edit: 2,
};

const paginationModel = {
  currentPage: 1,
  pageCount: 0,
  pageSize: 0,
  rowCount: null,
  firstRowOnPage: null,
  lastRowOnPage: null,
};

const textEditorStyleTypes = {
  richTextEditor: {
    id: 0,
    INLINE_STYLES: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Monospace", style: "CODE" },
    ],
    BLOCK_TYPES: [
      { label: "H1", style: "header-one" },
      { label: "H2", style: "header-two" },
      { label: "H3", style: "header-three" },
      { label: "H4", style: "header-four" },
      { label: "H5", style: "header-five" },
      { label: "H6", style: "header-six" },
      { label: "Blockquote", style: "blockquote" },
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
      // { label: 'Code Block', style: 'code-block' },
    ],
  },
  markDownEditor: {
    id: 1,
    INLINE_STYLES: [{ label: "Bold", style: "BOLD" }],
    BLOCK_TYPES: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  },
};

const transactionType = {
  // 0: "carPurchase",
  1: "servicePurchase",
  2: "accessoryPurchase",
};

const transactionActionType = {
  Add: 0,
  deduct: 1,
};

const processTypes = {
  edit: "edit",
  add: "add",
  delete: "delete",
};

const feedbackPageSize = 10;

const userRoles = {
  Admin: 0,
  Dealer: 1,
  Support: 2,
};

const TransactionSearchEnum = {
  SearchByUserId: 0,
  SearchByDealerId: 1,
  ShowAllAdmin: 2,
  ShowAllDealer: 3,
  SearchByVoucherCode:4
};

const transactionHistoryPageSize = 10;
const voucherCodeListPageSize = 10;

const categoryLengths = {
  headline: 45,
  subheadline: 95,
  useCaseInfoHeadline: 50,
  useCaseInfoText: 150,
};

const fuelTypes = [
  { value: 1, text: "Petrol" },
  { value: 2, text: "Diesel" },
  { value: 3, text: "Hybrid" },
  { value: 4, text: "Electric" },
];

const voucherCodeStatusEnum = [
  { value: 0, text: "used" },
  { value: 1, text: "notUsed" }
];


module.exports = {
  daysOfWeek,
  offerCategoryStatus,
  contentType,
  progressBarLevel,
  stringLengths,
  offerCategoryTypes,
  ingressPath,
  offerDetailsNextStep,
  eventDetailsNextStep,
  benefitOfferNextStep,
  loyaltyProgramCards,
  loyaltyTransactionTypes,
  loyaltyDeductTransactionTypes,
  timezones,
  dateTimeFormats,
  currencies,
  maxFileSizes,
  customerRecordByType,
  membershipStatus,
  membershipStatusForSearchBar,
  customerDatabasePageSize,
  customerDBSearch,
  paginationModel,
  textEditorStyleTypes,
  processTypes,
  loyaltyProgramId,
  transactionActionType,
  transactionType,
  feedbackPageSize,
  WhatsNewprocessTypes,
  userRoles,
  TransactionSearchEnum,
  transactionHistoryPageSize,
  voucherCodeListPageSize,
  categoryLengths,
  fuelTypes,
  voucherCodeStatusEnum
};
