import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { withRouter, Redirect } from "react-router-dom";
import queryString from "query-string";

import * as AuthActions from "../../redux/auth";
import * as NetworkServices from "../../services/networkServices";
import * as Endpoints from "../../const/endpoints";

import axios from "axios";
import { handleResponseDataCode } from "../../helper/errorHandler";
import { responseCode } from "../../const/responseCode";

const AuthCallback = (props) => {
  const { authActions, location, userLoggedIn } = props;
  const values = queryString.parse(location.search);
  useEffect(() => {
    let authCode = values.auth_code;

    authActions.exchangeToken(authCode);
  }, []);
  return userLoggedIn ? <Redirect to="/" /> : <div />;
};

const mapStateToProps = (state) => ({
  userLoggedIn: state.auth.userLoggedIn,
});

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthCallback));
