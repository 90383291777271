import moment from 'moment-timezone';
import { timezones } from '../const/global';

export const getCurrentDateWithTimezone = () => {
    return moment().tz(timezones.kor.region).format();
}

export const unixTimestampToDate = () => {
    return moment().format('DD.MM.YYYY HH:mm');
}

export const timeConversionUtcToUtc9 = (utc) => {
    // utc9 = moment(utc, 'HH:mm).tz(timezones.kor.region).format('HH:mm'); // This converts current timezone(+3) to desired timezone.(+9)
    let utc9 = moment.utc(utc, 'HH:mm').tz(timezones.kor.region).format('HH:mm'); // This suppose given parameter as utc.(+0)
    return utc9;
}


export const getCurrentDateUTC = () => {
    var m = moment().utcOffset(0);
    m.set({hour:0,minute:0,second:0,millisecond:0})
    return m.format();
}

export const getGivenDateUTC = (date) => {
    var m = moment(date).utcOffset(0);
    m.set({hour:0,minute:0,second:0,millisecond:0})
    return m.toISOString();
}