import axios from 'axios';
import store from '../store/index';
import * as Endpoints from '../const/endpoints';
import { handleResponseDataCode, handleResponseError } from '../helper/errorHandler';

export function requestData(requestType, path, data, contentType, responseType) {
	let headers = {};
	contentType = !contentType ? 'application/json' : contentType; // application/json, form-data, text/csv
	responseType = !responseType ? 'json' : responseType; // json, arraybuffer, text
	headers =
	{
		'Content-Type': contentType,
		'Pragma': 'no-cache',
		'X-AccessToken': store.getState().auth.accessToken // access_token is taken from Redux Store.
	}

	return new Promise((resolve, reject) => {
		axios({
			url: path,
			baseURL: Endpoints.baseUrl,
			headers: headers,
			method: requestType,
			data: data,
			responseType: responseType
		})
			.then(response => {
				if (response.data) {
					path !== Endpoints.logout && handleResponseDataCode(response.data);
				}
				resolve(response);
				//	console.log(response.request.data); // http 2xx code, handle BE error cases here.
			})
			.catch(error => {
				handleResponseError(error);
				reject(error);
			});
	});
}

export function requestImage(requestType, path, data) {
	return new Promise((resolve, reject) => {
		axios({
			url: path,
			baseURL: Endpoints.blobStorageBaseUrl,
			method: requestType,
			data: data,
			responseType: 'arraybuffer'
		})
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				resolve('')
			});
	});
}

export function downloadInvoice(path) {
	return new Promise((resolve, reject) => {
		axios({
			baseURL: path,
			method: 'GET',
			data: null,
			responseType: 'blob'
		})
			.then(response => {
				resolve(response);
			})
			.catch(error => {
				resolve('')
			});
	});
}

