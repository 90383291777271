
export const convertArrayBufferToBase64 = (arrayBuffer) => {
    let base64String = '';
    let binary = '';
    let bytes = new Uint8Array(arrayBuffer);
    for (var i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    base64String = window.btoa(binary);
    return base64String;
}

export const downloadFile = (file, fileName, contentType) => {

    var blob = new Blob([file], { type: contentType });
    var link = document.createElement('a');

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName); // IE & Edge fix for downloading blob files.
    } else {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

export const getAllowedFileTypes = (fileType) => { // application/pdf,image/jpeg,image/png,.xlsx
    let fileTypes = ['pdf', 'png', 'jpeg', 'xlsx'];
    let allowedFileTypes = '';
    fileTypes.forEach((file) => {
        fileType.includes(file) && (allowedFileTypes += file.toUpperCase() + ', ');
    })
    return allowedFileTypes.substring(0, allowedFileTypes.length - 2);
}

export const fileToBase64 = (file) => {
    const fileReader = new FileReader();
    let promise = new Promise((resolve, reject) => {
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.readAsDataURL(file);
    });
    return promise;
}