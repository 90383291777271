import showdown from 'showdown';

export const removeMultipleSpaces = (string) => {
    let trimmedString = JSON.parse(JSON.stringify(string).replace(/\s+/g, ' ').trim());
    return trimmedString;
}

export const convertiso8859ToUTF8 = (input) => {
    return decodeURIComponent(escape(input));
}

export const htmlToMarkdown = (html) => {
    var converter = new showdown.Converter();
    let markdown = (converter.makeMarkdown(html)).replace(/\n\n/g, '\n');
    //Remove last newline character ↵
    markdown = markdown.replace(/^\s+|\s+$/g, "");
    return markdown;
}

export const markdownToHtml = (markdown) => {
    var converter = new showdown.Converter();
    let html =markdown ? (converter.makeHtml(markdown.replace(/\n/g,'\n\n'))): "";
    return html;
}
