import { combineReducers } from "redux";
import language from "./language";
import route from "./route";
import offerCategory from "./offerCategory";
import offer from "./offer";
import fileUpload from "./fileUpload";
import modal from "./modal";
import loyaltyProgram from "./loyaltyProgram";
import auth from "./auth";
import logExport from "./logExport";
import customerDB from "./customerDB";
import company from "./company";
import whatisnew from './whatIsNew'
import feedback from './feedback';
import bookingHistory from "./bookingHistory";

const rootReducer = combineReducers({
  language,
  route,
  offerCategory,
  offer,
  fileUpload,
  modal,
  loyaltyProgram,
  auth,
  logExport,
  customerDB,
  company,
  feedback,
  bookingHistory,
  whatisnew
});

export default rootReducer;
