import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withNamespaces } from 'react-i18next';
import { withRouter, Switch, Route } from 'react-router-dom';
import indexRoutes from './routes/indexRoutes';
import * as AuthActions from './redux/auth';
import * as LanguageActions from './redux/language'

import { ingressPath } from './const/global';
import AuthCallback from './pages/AuthCallback/AuthCallback';
import PrivateRoute from "./helper/PrivateRoute";

import 'date-input-polyfill';
import 'promise-polyfill/src/polyfill';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/overridden.css';
import './index.css';
import './pages/OfferCategory/OfferCategory.scss';
import './assets/sass/public.css';
class App extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      localStorage.setItem('path', location.pathname);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { i18n, selectedLanguage } = nextProps;
    if (this.props.selectedLanguage !== selectedLanguage) {
      localStorage.setItem('lang', selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
  }
  componentDidMount() {
    let selectedLanguage = localStorage.getItem('lang');
    if(selectedLanguage) {
      this.props.languageActions.changeLanguage(selectedLanguage);
    }
  }

  render() {
    return (
      <Switch>
        <Route
          path = {`/${ingressPath}/authcallback`}
          component={() => <AuthCallback />}
          exact={true}
        />
        {indexRoutes.map((prop, key) => {
          return (
            <PrivateRoute
              path={prop.path}
              component={prop.component}
              key={key}
              exact={prop.exact}
            />
          )
        })}
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    languageActions: bindActionCreators(LanguageActions, dispatch)
  }
}

export default withRouter(withNamespaces('common')(connect(mapStateToProps, mapDispatchToProps)(App)));